import { Button } from 'antd';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { selectLang, selectSite } from '@/redux/common/commonSlice';
import { useNavigate } from "react-router-dom";
import * as signalr from '@/services/signalr'

export default function End({ history }) {
  const navigate = useNavigate()
  const lang = useSelector(selectLang);
  const site = useSelector(selectSite)
  // const goBack = () => {
  //   history.goBack();
  // };

  return (
    <div className="End__CONTAINER">
      <div className="End__HEADER__CONTAINER">
        <h3 className="End__HEADER__TEXT">
            SmartThings
        </h3>
        <h4 className="End__SUBHEADER__TEXT">
            {(lang['Ending_Mobile']['1'] || '').replace(/\\n/gi, '\n')}
        </h4>
      </div>

      <div className="End__CONTENT__CONTAINER">
        <Row>
          <Col span={24}>
            <p className="txt">
              {(lang['Ending_Mobile']['2'] || '').replace(/\\n/gi, '\n')}
            </p>
            <div className="btn_box">
              <Button className="black"  onClick={() => {
                signalr.sender.buttonClick(`bt_scenario`);
                navigate(`/`);
              }}>Go back</Button>
              <a target="_self" href={site} rel="noreferrer">
                <Button className="ml-10 site_link_btn" onClick={() => {
                  signalr.sender.buttonClick(`bt_Idle`);
                  setTimeout(()=>signalr.disConnectionHub(), 1500)
                }}>Samsung.com</Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>

      <div className="End__FOOTER__CONTAINER">
        <p className="End__FOOTER__TEXT">Copyright 2022 SAMSUNG ALL Rights Reserved</p>
      </div>
    </div>
  );
}
