module.exports = Object.freeze({
  DISCONNECT_TIMEOUT_TIME: 1000 * 90, //1분
  SCENARIO_LIST: {
    'flow_01':{
      id: 'flow_01',
      index: 1,
      start_btn_index: 1,
      title: 'Work from home #1',
      title_lang_id: '2',
      content: 'Make video conferencing with large screen',
      content_lang_id: '3',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1585314294023-215a865ddee1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1584905066893-7d5c142ba4e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S01_Thumbnail',
      isEnable: true
    },
    'flow_02':{
      id: 'flow_02',
      index: 2,
      start_btn_index: 2,
      title: 'Work from home #2',
      title_lang_id: '4',
      content: 'Try working with NEO QLED+S22U',
      content_lang_id: '5',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1585314294023-215a865ddee1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1584905066893-7d5c142ba4e1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S02_Thumbnail',
      isEnable: true
    },
    'flow_03':{
      id: 'flow_03',
      index: 3,
      start_btn_index: 3,
      title: 'Set the mood',
      title_lang_id: '6',
      content: 'While watching a movie, search for a product through the App cat function',
      content_lang_id: '7',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S03_Thumbnail',
      isEnable: true
    },
    'flow_04':{
      id: 'flow_04',
      index: 4,
      start_btn_index: 4,
      title: 'Stay Fit & Healthy',
      title_lang_id: '9',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '10',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S04_Thumbnail',
      isEnable: true
    },
    'flow_05':{
      id: 'flow_05',
      index: 5,
      start_btn_index: 5,
      title: 'Stay Fit & Healthy',
      title_lang_id: '11',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '12',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S05_Thumbnail',
      isEnable: true
    },
    'flow_06':{
      id: 'flow_06',
      index: 6,
      start_btn_index: 5,
      title: 'Stay Fit & Healthy',
      title_lang_id: '13',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '14',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S06_Thumbnail',
      isEnable: true
    },
    'flow_07':{
      id: 'flow_07',
      index: 7,
      start_btn_index: 6,
      title: 'Stay Fit & Healthy',
      title_lang_id: '15',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '16',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S07_Thumbnail',
      isEnable: true
    },
    'flow_08':{
      id: 'flow_08',
      index: 8,
      start_btn_index: 7,
      title: 'Stay Fit & Healthy',
      title_lang_id: '17',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '18',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S08_Thumbnail',
      isEnable: false
    },
    'flow_09':{
      id: 'flow_09',
      index: 9,
      start_btn_index: 8,
      title: 'Stay Fit & Healthy',
      title_lang_id: '19',
      content: 'Home Fitness with\nTrainer on screen',
      content_lang_id: '20',
      tags: '#Tag1,#Tag2,#Tag3,#Tag4,#Tag5',
      preview_image_url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      thumbnail_image_url: 'https://images.unsplash.com/photo-1577979749830-f1d742b96791?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      thumbnail_lang_key_name: 'S09_Thumbnail',
      isEnable: false
    }
  }
});
