import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton
} from 'react-accessible-accordion';
import {SCENARIO_LIST} from '@/constants'
import { setLang, setSite, setIsShowThumbnailMessageBox, selectIsLoadLangData, setLangCode, setServerCode, selectIsEnableScenario5, selectIsEnableScenario6, selectIsEnableScenario9, selectLang } from '@/redux/common/commonSlice'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as signalr from '@/services/signalr'
import { CONNECTION_INFO } from '@/config';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Modal from "@/components/Modal";

export default function ScenarioList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const isLoadLangData = useSelector(selectIsLoadLangData);
  const isEnableScenario5 = useSelector(selectIsEnableScenario5);
  const isEnableScenario6 = useSelector(selectIsEnableScenario6);
  const isEnableScenario9 = useSelector(selectIsEnableScenario9);

  const [accordionItemIndex, setAccordionItemIndex] = useState(null);
  const [isShowDisclaimer, setIsShowDisclaimer] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const groupValue = parameters.get('group') || (process.env.NODE_ENV !== 'production' ? 'store1' : '');
    const codeValue = parameters.get('code') || (process.env.NODE_ENV !== 'production' ? '123456' : '');
    const serverValue = parameters.get('server') || (process.env.NODE_ENV !== 'production' ? 'us' : '');
    const langCodeValue = parameters.get('lang') || (process.env.NODE_ENV !== 'production' ? 'en' : '');

    if(serverValue){
      dispatch(setServerCode(serverValue));
    }

    if(langCodeValue){
      dispatch(setLangCode(langCodeValue));
    }

    if(!isLoadLangData && langCodeValue){
      const body = document.getElementsByTagName('body')[0];

      switch (langCodeValue) {
        case 'kr':
        case 'en':
          body.className = 'lang1';
          break;
        case 'vie':
          body.className = 'lang3';
          break;
        default:
          body.className = 'lang2';
      };

      fetch(`${CONNECTION_INFO.host}/api/languagedata/latest_mobiledata_json?lang=${langCodeValue}`)
      .then(response => response.json())
      .then(response => dispatch(setLang(response)));
    }

    if(groupValue && codeValue){
      fetch(`${CONNECTION_INFO.host}/api/serviceinfo/site_url?group=${groupValue}`)
      .then(response => response.json())
      .then(response => dispatch(setSite(response.data)));

      signalr.getConnectionHub(groupValue, codeValue).then(connectionHub=>{
        if(!connectionHub){
          dispatch(setIsShowThumbnailMessageBox(false));
          navigate(`/disconnect`, { replace: true });
        }else{
          connectionHub.on('METHOD_W_to_C', (conn_id, key, message) => {
            if(key === 'video_complete'){
              if(navigator.vibrate){
                navigator.vibrate([100, 50, 100, 50, 100]);
              }
              dispatch(setIsShowThumbnailMessageBox(false));
              navigate("/end", { replace: true });
            }else if(key === 'req_disconnect'){
              dispatch(setIsShowThumbnailMessageBox(false));
              navigate(`/disconnect`, { replace: true });
            }else if(key === 'interaction_point'){
              switch (message) {
                case 'flow_01':
                  // navigate(`/${message}/interactionScenario1Step1`, { replace: true });
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  dispatch(setIsShowThumbnailMessageBox(true));
                  break;
                case 'flow_02':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/flow_04/interactionScenario4`, { replace: true });
                  break;
                case 'flow_03':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/flow_05/interactionScenario5`, { replace: true });
                  break;
                // case 'flow_03':
                //   navigate(`/${message}/interactionScenario3Step1`, { replace: true });
                //   break;
                // case 'flow_04':
                //   navigate(`/${message}/interactionScenario4`, { replace: true });
                //   break;
                case 'flow_05':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/flow_07/interactionScenario7`, { replace: true });
                  break;
                case 'flow_06':
                  if(navigator.vibrate){
                    navigator.vibrate([100, 50, 100, 50, 100]);
                  }
                  navigate(`/flow_09/interactionScenario9`, { replace: true });
                  break;
                default:
                  dispatch(setIsShowThumbnailMessageBox(false));
                  navigate(`/Error`, { replace: true });
              }
            } else {
              dispatch(setIsShowThumbnailMessageBox(false));
              navigate(`/notfound`, { replace: true });
            }
          });

          connectionHub.onclose(() => {
            navigate(`/disconnect`, { replace: true });
          })
        }
      })
    }
  }, [navigate, dispatch, isLoadLangData]);

  const changeAccordion = data => {
    if(data.length){
      setAccordionItemIndex(data[0])
    }else{
      setAccordionItemIndex(0)
    }
  }

  useEffect(() => {
    if(accordionItemIndex>0){
      if(document.body.requestFullscreen){
        document.body.requestFullscreen();
      }

      signalr.sender.buttonClick(`bt_#${SCENARIO_LIST['flow_0'+accordionItemIndex]['start_btn_index']}_start`);
      navigate(`/flow_0${accordionItemIndex}/thumbnail`);
    }
  }, [navigate, accordionItemIndex]);

  return (
    <div className="ScenarioList__CONTAINER">
      <div className="ScenarioList__HEADER__CONTAINER">
        <h3 className="ScenarioList__HEADER__TEXT" style={{}}>
            SmartThings
        </h3>
        <h4 className="ScenarioList__SUBHEADER__TEXT">
            {(lang['Scenario_List_Mobile']['1'] || '').replace(/\\n/gi, '\n')}
        </h4>
      </div>
      <div className="ScenarioList__CONTENT__CONTAINER">
        <div className="top_btn_container right">
          <a className="btn" href={()=> false} onClick={() => {
            setIsShowDisclaimer(true);
          }}>Disclaimer</a>
        </div>
        <Accordion style={{border: 'none'}} allowZeroExpanded="0" onChange={data=>changeAccordion(data)}>
          {
            Object.entries(SCENARIO_LIST).map(arr => {
              const item = arr[1];
              return <AccordionItem key={item.id} uuid={item.index} style={{display: item.id === 'flow_05' ? (item.isEnable && isEnableScenario5 ? 'block' : 'none') : item.id === 'flow_06' ? (item.isEnable && isEnableScenario6 ? 'block' : 'none') : item.id === 'flow_09' ? ( item.isEnable && isEnableScenario9 ? 'block' : 'none' ) : item.isEnable ? 'block' : 'none'}}>
                  <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="accordion_top">
                          <div className="img_box">
                            {isLoadLangData ? <img width="86px" height="78px" src={require(`@/assets/images/min_img_s${item.index}.jpg`)} alt={item.title}/> : <Skeleton width="86px" height="78px"/> }
                          </div>
                          <div className="txt_box">
                            <h4 className="tit samsung_sands_b">
                              {isLoadLangData ? (lang['Scenario_List_Mobile'][item.title_lang_id] || '').replace(/\\n/gi, '\n') : <Skeleton/>}
                            </h4>
                            <p className="tit_des">
                              {isLoadLangData ? (lang['Scenario_List_Mobile'][item.content_lang_id] || '').replace(/\\n/gi, '\n') : <Skeleton/>}
                            </p>
                          </div>
                        </div>
                      </AccordionItemButton>
                  </AccordionItemHeading>
              </AccordionItem>
            })
          }
        </Accordion>
      </div>
      {isShowDisclaimer ? <Modal message={isLoadLangData ? (lang['Scenario_List_Disclaimer'][1] || '').replace(/\\n/gi, '\n') : ""}  setIsShowDisclaimer={(value) => {
        setIsShowDisclaimer(value);
      }} /> : null}
    </div>
  );
}
