import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DISCONNECT_TIMEOUT_TIME } from '@/constants'
// import * as helper from '@/helper'
import * as signalr from '@/services/signalr'
import Router from '@/routes'

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => navigate(`/disconnect`, { replace: true }), DISCONNECT_TIMEOUT_TIME);
    return () => {
      clearTimeout(timer)
    };
  }, [navigate]);

  useEffect(() => {
    window.onpopstate = e => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('window.onpopstate', e)
      }
      navigate(`/disconnect`, { replace: true })
    }

    if(['/','/disconnect','/loading'].indexOf(location.pathname) === -1){
      signalr.getConnectionHub().then(result=>{
        if(!result){
          navigate(`/disconnect`, { replace: true })
        }
      })
    }
  }, [location, navigate]);

  // useEffect(() => {
  //   const visibilityChange = helper.getBrowserVisibilityProp()
  //
  //   document.addEventListener(visibilityChange, ()=>{
  //     if(helper.getIsDocumentHidden()){
  //       navigate(`/disconnect`, { replace: true });
  //     }
  //   })
  //
  //   return () => {
  //     document.removeEventListener(visibilityChange, ()=>{})
  //   }
  // },[navigate])

  return (
    <Router/>
  );
}

export default App;
