import { createSlice } from '@reduxjs/toolkit';

const defaultLang = {
  "Navi": {
    "1": "Favorites",
    "2": "Device",
    "3": "SmartLife",
    "4": "Automation"
  },
  "IDLE_Mobile": {
    "1": "Touch the start button"
  },
  "Ending_Mobile": {
    "1": "Share your smart life now",
    "2": "Exprience the producrs on display. Detail can be found by going to the site"
  },
  "Loding": {
    "1": "Loding..."
  },
  "Error1": {
    "1": "It's in use. Please try again later. Details of the product can be found in the site"
  },
  "Error2": {
    "1": "Page not found"
  },
  "Scenario_List_Mobile": {
    "1": "Please select the scenario you want to see",
    "2": "Work from home",
    "3": "Try working with NEO QLED+S22U",
    "4": "Set the mood",
    "5": "While watching a movie, search for a product through the App cat function",
    "6": "Stay fit & Healthy",
    "7": "Always enjoy home training with experts/friends at home",
    "8": "Stay fit & Healthy",
    "9": "Eat a meal easily after exercising",
    "10": "Save your energy",
    "11": "Energy care that saves you money on your own",
    "12": "",
    "13": "",
    "14": "",
    "15": ""
  },
  "S01_Thumbnail": {
    "1": "#01 Work from home",
    "2": "Check out the contents on NEO QLED"
  },
  "S01_Mobile": {
    "1": "Favorites"
  },
  "S01_1": {
    "1": "Samsung NEO QLED",
    "2": "Livingroom",
    "3": "Camera Sharing",
    "4": "Enjoy the camera function by sharing your mobile phone camera",
    "5": "Magie Screen",
    "6": "Decorate the space according to your lifestyle",
    "7": "Smart Quality Adjustment",
    "8": "Adjust the image quality so that it is close to the image quality intended by the video producer",
    "9": "Universal Guide",
    "10": "Please watch the recommended TV programs and movies"
  },
  "S02_Thumbnail": {
    "1": "#02 Try working with NEO QLED+S22U",
    "2": "Check out the contents on NEO QLED"
  },
  "S02": {
    "1": "Do you want to check the App cast wardrobe?"
  },
  "S03_Thumbnail": {
    "1": "#03 Stay fit & Healthy",
    "2": "Check out the contents on NEO QLED"
  },
  "S03": {
    "1": "Favorites"
  },
  "S03_1": {
    "2": "Livingroom",
    "3": "Camera Sharing",
    "4": "Enjoy the camera function by sharing your mobile phone camera",
    "5": "Magie Screen",
    "6": "Decorate the space according to your lifestyle",
    "7": "Smart Quality Adjustment",
    "8": "Adjust the image quality so that it is close to the image quality intended by the video producer",
    "9": "Universal Guide"
  },
  "S04_Thumbnail": {
    "1": "#04 Stay fit & Healthy",
    "2": "Check out the contents on NEO QLED"
  },
  "S04": {
    "1": "SmartThings Home Life",
    "2": "Cooking",
    "3": "Try cooking while recommennding your own recipes and diet plans",
    "4": "Energy",
    "5": "Save Electricity for home appliances",
    "6": "Pet Care",
    "7": "Don't worry if your pet is home alone.With Smart Singing Pet Care, please check if your pets are well anytime, anywhere and take care of them.",
    "8": "Clothing Care",
    "9": "Save time and manage clothes easily"
  },
  "S05_Thumbnail": {
    "1": "#05 Save your energy",
    "2": "Check out the contents on NEO QLED"
  },
  "S05": {
    "1": "SmartThings"
  }
}

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    site: 'https://www.samsung.com',
    langCode: 'en',
    serverCode: 'us',
    lang: defaultLang,
    isLoadLangData: false,
    isShowThumbnailMessageBox: false,
  },
  reducers: {
    setSite: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setSite>>', action.payload)
      }
      state.site = action.payload
    },
    setLangCode: (state, action) => {
      state.langCode = action.payload
    },
    setServerCode: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setServerCode>>', action.payload)
      }
      state.serverCode = action.payload
    },
    setLang: (state, action) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('setLang>>', action.payload)
      }
      state.isLoadLangData = action.payload ? true : false
      state.lang = action.payload
    },
    setIsShowThumbnailMessageBox: (state, action) => {
      state.isShowThumbnailMessageBox = action.payload
    }
  },
  extraReducers: (builder) => {

  },
});

export const { setSite, setLangCode, setServerCode, setLang, setIsShowThumbnailMessageBox } = commonSlice.actions;

export const selectSite = (state) => state.common.site;
export const selectlangCode = (state) => state.common.langCode;
export const selectLang = (state) => state.common.lang;
export const selectIsLoadLangData = (state) => state.common.isLoadLangData;
export const selectIsShowThumbnailMessageBox = (state) => state.common.isShowThumbnailMessageBox;
export const selectIsEnableScenario5 = (state) => ['UK', 'US', 'CA'].indexOf(state.common.serverCode.toUpperCase()) === -1;
export const selectIsEnableScenario6 = (state) => ['UK', 'US', 'CA'].indexOf(state.common.serverCode.toUpperCase()) > -1;
export const selectIsEnableScenario9 = (state) => ['UK', 'US'].indexOf(state.common.serverCode.toUpperCase()) > -1;

export default commonSlice.reducer;
