import * as signalr from '@/services/signalr'

import gnbIcon1 from '@/assets/images/s9/gnb_icon_1.png';
import gnbIcon2 from '@/assets/images/s9/gnb_icon_2.png';
import gnbIcon3 from '@/assets/images/s9/gnb_icon_3.png';

import icon_circle from '@/assets/images/s9/icon_circle.png';
import icon_plus from '@/assets/images/s9/icon_plus.png';

import icon1 from '@/assets/images/s9/icon01.png';
import icon2 from '@/assets/images/s9/icon02.png';
import icon3 from '@/assets/images/s9/icon03.png';
import icon4 from '@/assets/images/s9/icon04.png';
import icon5 from '@/assets/images/s9/icon05.png';
import icon6 from '@/assets/images/s9/icon06.png';

import tapGesture from '@/assets/images/s4/tap_gesture.svg';

import { useParams, useNavigate } from "react-router-dom";

export default function InteractionScenario4() {
  const navigate = useNavigate();
  const params = useParams();

  return (
      <div className="smartview_page6_container">
          <div className="smartview_page6_header">
            <p>Samsung</p>
          </div>
          <div className="smartview_page6_body">
              <div className="smartview_container">
                  <div className="smartview_page6_tit">
                      <ul>
                          <li><img src={icon_circle} alt="SmartThings"/></li>
                          <li><img src={icon_plus} alt="SmartThings"/></li>
                      </ul>
                  </div>
                  <div className="smartview_page6_icon">
                      <ul>
                          <li>
                            <div className="dark_screen"></div>
                          </li>
                          <li style={{position: 'relative'}} onClick={() => {
                            signalr.sender.buttonClick('bt_#8_interaction');
                            navigate(`/${params.scenario_id}/thumbnail`, { replace: true });
                          }}>
                              <img src={icon1} alt="SmartThings" style={{position: 'relative', zIndex: 2}}/>
                              <img src={tapGesture} alt="" className="tap_gesture" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-45%, -45%)', zIndex: 3}}/>
                              <div className="inner_tx">SmartThings</div>
                          </li>
                          <li>
                              <img src={icon2} alt="Samsung Health"/>
                              <div className="inner_tx">Samsung<br/>Health</div>
                          </li>
                          <li>
                              <img src={icon3} alt="Bixby"/>
                              <div className="inner_tx">Bixby</div>
                          </li>
                          <li>
                              <img src={icon4} alt="Play Store"/>
                              <div className="inner_tx">Play Store</div>
                          </li>
                          <li>
                              <img src={icon5} alt="Internet"/>
                              <div className="inner_tx">Internet</div>
                          </li>
                          <li>
                              <img src={icon6} alt="My Files"/>
                              <div className="inner_tx">My Files</div>
                          </li>

                      </ul>
                  </div>
              </div>
          </div>
          <div className="smartview_page6_footer">
            <img src={gnbIcon1} alt="gnbIcon1"/>
            <img src={gnbIcon2} alt="gnbIcon2"/>
            <img src={gnbIcon3} alt="gnbIcon3"/>
          </div>
      </div>
  );
}
